import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import AOS from 'aos';
import Gridzy from './plugins/gridzy.js';
import CookieNotification from "bb-cookie-notification";
import ModuloBox from './plugins/modulobox.js';
import Masonry from 'masonry-layout';
import axios from 'axios';
import './plugins/jquery.easing.js';
import './plugins/animated-headline.js';
import './plugins/fullscreenForm.js';
import './plugins/classie.js';
import anime from 'animejs/lib/anime.es.js';

AOS.init();

// BenedenBoven cookiescript
new CookieNotification({
    privacyPage: '/privacy-statement'
});

if(document.querySelector('.scrolldown')) {
    const intersectionObserver = new IntersectionObserver((entries) => {
        if(entries[0].intersectionRatio <= 0) {
            document.querySelector('.scrolldown').style.opacity = 1;
        } else {
            document.querySelector('.scrolldown').style.opacity = 0;
        }
    });

    intersectionObserver.observe(
        document.querySelector('.project-image-container img:last-child'),
    );
}

if(document.querySelector('.anim-text')) {
    document.querySelectorAll('.anim-text').forEach((animatingElement, k) => {
        let delay = parseInt(animatingElement.dataset.animDelay) > 0 ? parseInt(animatingElement.dataset.animDelay) : 0;
        animatingElement.innerHTML = '<span class="word"><span>' + animatingElement.innerText.split(' ').join('</span></span> <span class="word"><span>') + '</span></span>'
        animatingElement.style.opacity = 1;
        setTimeout(() => {

            anime({
                targets: animatingElement.querySelectorAll('.word > span'),
                translateY: [50, 0],
                opacity: [0,1],
                easing: "easeOutExpo",
                duration: 1400,
                delay: (el, i) => 300 + 70 * i
            });
        }, delay);
    });
}

if(document.querySelector('.anim-element')) {
    document.querySelectorAll('.anim-element').forEach((animatingElement) => {
        let delay = parseInt(animatingElement.dataset.animDelay) > 0 ? parseInt(animatingElement.dataset.animDelay) : 0;
        setTimeout(() => {
            anime({
                targets: animatingElement,
                translateY: [50, 0],
                opacity: [0,1],
                easing: "easeOutExpo",
                duration: 1400,
                delay: (el, i) => 300 + 70 * i
            });
        }, delay);
    })
}

if(document.querySelector('.anim-logo')) {
    document.querySelectorAll('.anim-logo').forEach((animatingElement, i) => {
        setTimeout(() => {
            animatingElement.classList.add('init');
        }, 1500 + (i * 100));
    });
}

if(document.querySelector('.next-project')) {
    document.querySelectorAll('.next-project').forEach((el) => {
        el.addEventListener('click', (e) => {
            window.location = e.currentTarget.querySelector('a').href;
        });
    } );
}

if(document.getElementById('application-form')) {
    FilePond.registerPlugin(FilePondPluginFileValidateType);
    FilePond.registerPlugin(FilePondPluginFileEncode);
    const filepond = FilePond.create(document.querySelector('.files'), {
        allowMultiple:                         true,
        instantUpload:                         false,
        required:                              true,
        acceptedFileTypes:                     ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        fileValidateTypeLabelExpectedTypesMap: {
            'image/png':                                                               '.png',
            'image/jpeg':                                                              '.jpg',
            'image/jpg':                                                               '.jpg',
            'application/pdf':                                                         '.pdf',
            'application/msword':                                                      '.doc',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx'
        },
        labelFileTypeNotAllowed:               'Onjuist bestandstype!',
        fileValidateTypeLabelExpectedTypes:    'Alleen {allButLastType} en {lastType} zijn togestaan',
        labelIdle:                             'Sleep je cv/motivatie hiernaartoe of <span class="filepond--label-action"> blader </span>',
        credits: false
    });

    let currentlySending  = false;
    const applicationForm = document.getElementById('application-form');
    applicationForm.addEventListener('submit', (event) => {
        event.preventDefault();

        if(currentlySending === false) {

            currentlySending                                          = true;
            document.querySelector('button[type="submit"]').innerHTML = '<i class="fas fa-circle-notch fa-spin"></i>';
            document.querySelector('button[type="submit"]').disabled  = true;

            const formData = new FormData(applicationForm);
            formData.append('g-recaptcha-response', grecaptcha.getResponse());
            const validationAlert = document.getElementById('application-form-validation');

            document.querySelectorAll('.invalid-feedback').forEach((element) => {
                if(!element.classList.contains('d-none')) {
                    element.classList.add('d-none');
                }
            });

            document.querySelectorAll('.is-invalid').forEach((element) => {
                element.classList.remove('is-invalid');
            });

            validationAlert.classList.add('hidden');
            validationAlert.classList.remove('alert-danger');
            axios.post(applicationForm.action, formData).then(response => {
                if(response.data.success) {
                    validationAlert.innerHTML = response.data.message;
                    validationAlert.classList.add('alert-success');
                    validationAlert.classList.remove('hidden');

                    applicationForm.innerHTML = '';
                } else {
                    currentlySending                                  = false
                    applicationForm.querySelector('button').innerHTML = 'Verstuur';
                    applicationForm.querySelector('button').disabled  = false;
                }
            }).catch(exception => {
                if(exception.response.data.errors) {
                    for(let error in exception.response.data.errors) {
                        const el = applicationForm.querySelector('[name="' + error + '"]');
                        el.classList.add('is-invalid');
                        if(el.parentNode.querySelector('.d-none') !== null) {
                            el.parentNode.querySelector('.d-none').classList.remove('d-none');
                        }
                    }
                }
                currentlySending                                  = false
                applicationForm.querySelector('button').innerHTML = 'Verstuur';
                applicationForm.querySelector('button').disabled  = false;
                grecaptcha.reset();
            });
        }
    });
}

document.querySelectorAll('img:not([src])').forEach((element) => {
    element.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
});

(function($) {
    "use strict"; // Start of use strict

    if(document.querySelector('.grid-masonry')) {
        const grid = new Masonry(document.querySelector('.grid-masonry'), {
            itemSelector:    '.grid-masonry-item',
            columnWidth:     '.grid-masonry-sizer',
            percentPosition: true
        })

        if($('[data-filter]').length > 0) {
            $('[data-filter]').click(function(e) {
                e.preventDefault();

                $('.service-category-btn .active').removeClass('active');
                $(this).parent().addClass('active');

                var filterOn = $(this).data('filter');
                $('.grid-masonry .service:not(.' + filterOn + ')').hide().removeClass('masonry-item');
                $('.grid-masonry .service.' + filterOn).show().addClass('masonry-item');
                grid.layout();
            });

            if($('.service-category-btn .active').length > 0) {
                $('.service-category-btn .active > a').click();
            }
        }
    }

    if(typeof (clients) !== "undefined" && typeof (shownClients) !== 'undefined') {

        let randomNumber   = null;
        let previousNumber = null;

        /*setInterval(function() {
            let hoverElement = document.querySelector('.auto-hover');

            if(hoverElement !== null) {
                document.querySelector('.auto-hover').classList.remove('auto-hover');
            }

            while(randomNumber === null || randomNumber === previousNumber) {
                randomNumber = Math.floor(Math.random() * 6) + 1;
            }

            previousNumber = randomNumber;

            let element = document.querySelectorAll('.client-home')[randomNumber - 1];

            $(element).find('svg').stop().animate({
                opacity: 0
            }, 400, function() {
                $.get('http://benedenbovenv3.development.benedenboven.nl' + clients[0], function(data) {

                    console.log(data);
                    var $svg = $(data).find('svg');

                    $(element).find('svg').css({ 'opacity': 0 }).replaceWith($svg).stop().animate({
                        opacity: 1
                    }, 400);

                    element.classList.add('auto-hover');
                });

                //clients.splice(0, 1);
            });

            if(clients.length === 0) {
                var chosen = [];
                $('.client-home').each(function() {

                });
            }

            //element.querySelector('img').src = clients[0];
        }, 1000);*/
    }

    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
        if(location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target     = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if(target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - 70)
                }, 1000, "easeInOutExpo");
                return false;
            }
        }
    });

    // Append .background-image-holder <img>'s as CSS backgrounds
    $('.background-image-holder').each(function() {
        var imgSrc = $(this).children('img').attr('src');
        $(this).css('background', 'url("' + imgSrc + '")');
        $(this).children('img').hide();
        $(this).css('background-position', 'initial');
    });

    // Fade in background images
    setTimeout(function() {
        $('.background-image-holder').each(function() {
            $(this).addClass('fadeIn');
        });
    }, 200);

    //NAV
    $('.plus-btn').click(function() {
        $('body').toggleClass('menu-open');
    });

    //Map click interaction (contact page)
    $('.map-holder').click(function() {
        $(this).addClass('interact');
    });

    if($('.map-holder').length) {
        $(window).scroll(function() {
            if($('.map-holder.interact').length) {
                $('.map-holder.interact').removeClass('interact');
            }
        });
    }

    // Parallax header
    $(function() {
        // 	init scrollmagic
        var controller = new ScrollMagic.Controller();

        // 	loop through slides
        $(".parallax").each(function() {

            var bg = $(this).find(".parallax-image");

            // Add tweenmax for backgroundParallax
            var parallax = TweenMax.from(bg, 1, {
                y:    '-30%',
                ease: Power0.easeNone
            });

            // Create scrollmagic scene
            var parallaxScene = new ScrollMagic.Scene({
                triggerElement: this, // <-- Use this to select current element
                triggerHook:    1,
                duration:       '200%',
            })
                .setTween(parallax)
                .addTo(controller);
        });
    });

    // Mouse parallax
    var rect  = $('section')[0].getBoundingClientRect();
    var mouse = {
        x:     0,
        y:     0,
        moved: false
    };

    $("section").mousemove(function(e) {
        mouse.moved = true;
        mouse.x     = e.clientX - rect.left;
        mouse.y     = e.clientY - rect.top;
    });

    // Ticker event will be called on every frame
    TweenLite.ticker.addEventListener('tick', function() {
        if(mouse.moved) {
            parallaxIt(".mask", -10);
        }
        mouse.moved = false;
    });

    function parallaxIt(target, movement) {
        TweenMax.to(target, 0.4, {
            x: (mouse.x - rect.width / 2) / rect.width * movement,
            y: (mouse.y - rect.height / 2) / rect.height * movement
        });
    }

    $(window).on('resize scroll', function() {
        rect = $('section')[0].getBoundingClientRect();
    })

    // Gridzy
    $('.gridzy-walloffame-home').gridzy({
        spaceBetween:  3,
        desiredHeight: 150,
    });
    $('.gridzy-walloffame').gridzy({
        spaceBetween:  8,
        desiredHeight: 500
    });

    // Modulobox
    var modulobox = new ModuloBox({
        mediaSelector:      '.gridzy-walloffame figure > a, .hidden-gallery div',
        loop:               3,
        history:            true,
        controls:           ['zoom', 'play', 'fullScreen', 'download', 'share', 'close'],
        shareButtons:       ['facebook', 'googleplus', 'twitter', 'pinterest', 'linkedin', 'reddit', 'stumbleupon', 'tumblr', 'blogger', 'buffer', 'digg', 'evernote'],
        videoMaxWidth:      1440,
        minZoom:            1,
        zoomTo:             1.8,
        mouseWheel:         false,
        contextMenu:        false,
        scrollToZoom:       true,
        captionSmallDevice: false,
        thumbnails:         true,
        thumbnailsNav:      'centered',
        thumbnailSizes:     {
            1920: {
                width:  110,
                height: 80,
                gutter: 10
            },
            1280: {
                width:  90,
                height: 65,
                gutter: 10
            },
            680:  {
                width:  0,
                height: 0,
                gutter: 0
            }
        }
    });

    modulobox.on('beforeOpen.modulobox', function(gallery, index) {
        if(window.cubeRAF) {
            cancelAnimationFrame(window.cubeRAF);
            window.cubeRAF = false;
        }
    });

    modulobox.on('afterClose.modulobox', function(gallery, index) {
        if(typeof Event === 'function' && !window.cubeRAF) {
            window.dispatchEvent(new Event('scroll'));
        }
    });

    modulobox.init();

})(jQuery); // End of use strict

window.onload = function() {
    var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));

    if("IntersectionObserver" in window) {
        let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if(entry.isIntersecting) {
                    entry.target.classList.add("visible");
                    entry.target.style.backgroundImage = 'url("' + entry.target.dataset.backgroundImgReplace + '")';
                    entry.target.classList.remove('lazy-background');
                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });

        lazyBackgrounds.forEach(function(lazyBackground) {
            lazyBackgroundObserver.observe(lazyBackground);
        });
    }

    var lazyImages = [].slice.call(document.querySelectorAll("img.img-lazy"));

    if("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if(entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    //lazyImage.srcset = lazyImage.dataset.srcset;
                    lazyImage.classList.remove("img-lazy");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Possibly fall back to a more compatible method here
    }
};
